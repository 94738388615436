import PropTypes from 'prop-types';
import React, { Component } from 'react';
import View from 'spider/store/View';
import { Route as BaseRoute, Switch, Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';
import { ModuleRepository } from '_iae/module/repository';
// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const GlobalValueOverview = Load(() => import('../screen/GlobalValue/Overview'));
const GlobalValueEdit = Load(() => import('../screen/GlobalValue/Edit'));
const GlobalFileOverview = Load(() => import('../screen/GlobalFile/Overview'));
const GlobalFileEdit = Load(() => import('../screen/GlobalFile/Edit'));
const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(() => import('spider/semantic-ui/LoginForgot'));
const ResetPassword = Load(() => import('spider/semantic-ui/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const IntegrationOverview = Load(() => import('../screen/Integration/Overview'));
const ExactErrorOverview = Load(() => import('../screen/ExactError/Overview'));

const TransportCompanyOverview = Load(() => import('../screen/TransportCompany/Overview'));

const PurchaseOrderOverview = Load(() => import('../screen/PurchaseOrder/Overview'));
const SalesOrderOverview = Load(() => import('../screen/SalesOrder/Overview'));
const TransportOrderOverview = Load(() => import('../screen/TransportOrder/Overview'));
const DistributionOrderOverview = Load(() => import('../screen/DistributionOrder/Overview'));
const ArticleOverview = Load(() => import('../screen/Article/Overview'));
const DistributionManager = Load(() => import('../screen/DistributionManager'));
const DefectOverview = Load(() => import('../screen/Defect/Overview'));

const EmailTemplateOverview = Load(() => import('../screen/EmailTemplates/Overview'));
const EmailTemplateEdit = Load(() => import('../screen/EmailTemplates/Edit'));

const JanKredietOrderOverview = Load(() => import('../screen/JanKrediet/Orders'));
const JanKredietInboundOrderOverview = Load(() => import('../screen/JanKrediet/InboundOrders'));
const JanKredietOutboundOrderOverview = Load(() => import('../screen/JanKrediet/OutboundOrders'));
const JanKredietCancelledOverview = Load(() => import('../screen/JanKrediet/Cancelled'));


const AcceptTransportOrder = Load(() => import('../screen/TransportOrder/AcceptTransportOrder'));
const DeclineTransportOrder = Load(() => import('../screen/TransportOrder/DeclineTransportOrder'));


const SupplierDashboard = Load(() => import('screen/Supplier/SupplierDashboard'))

const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
        moduleRepository: PropTypes.instanceOf(ModuleRepository).isRequired
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        const { moduleRepository } = this.props;
        return rProps => <Screen {...rProps} viewStore={this.props.store} moduleRepository={moduleRepository}/>;
    };

    redirectFromHome = () => {
        const { currentUser } = this.props.store;

        let uri;
        if (currentUser.isSuperuser) {
            uri = '/assets/user/overview';
        } else if (currentUser.hasPermission('supply_chain.manage_purchaseorder')) {
            uri = '/supply-chain/distribution-manager';
        } else if (currentUser.hasPermission('supply_chain.manage_purchaseorder:supplier')) {
            uri = '/supplier/overview';
        } else if (currentUser.hasPermission('supply_chain.manage_transportorder:transport_partner')) {
            uri = '/supply-chain/transport-order/overview';
        } else {
            uri = '/account/details';
        }

        return <Redirect to={uri} />;
    };

    render() {
        const { moduleRepository } = this.props;

        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>

               <Route path="/assets/global-value/overview" render={this.route(GlobalValueOverview)}/>
                <Route path="/assets/global-value/:id/edit" render={this.route(GlobalValueEdit)}/>

                <Route path="/assets/global-file/overview" render={this.route(GlobalFileOverview)}/>
                <Route path="/assets/global-file/:id/edit" render={this.route(GlobalFileEdit)}/>

                <Route path="/assets/email-template/overview" render={this.route(EmailTemplateOverview)} />
                <Route path="/assets/email-template/add" render={this.route(EmailTemplateEdit)}/>
                <Route path="/assets/email-template/:id/edit" render={this.route(EmailTemplateEdit)}/>

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>

                <Route path="/assets/transport-company/overview" render={this.route(TransportCompanyOverview)}/>
                <Route path="/assets/integration/overview/:result?/:error?" render={this.route(IntegrationOverview)}/>
                <Route path="/assets/exact-error/overview" render={this.route(ExactErrorOverview)}/>

                <Route path="/supply-chain/distribution-manager" render={this.route(DistributionManager)}/>
                <Route path="/supply-chain/purchase-order/overview" render={this.route(PurchaseOrderOverview)}/>
                <Route path="/supply-chain/sales-order/overview" render={this.route(SalesOrderOverview)}/>
                <Route path="/supply-chain/transport-order/overview" render={this.route(TransportOrderOverview)}/>
                <Route path="/supply-chain/distribution-order/overview" render={this.route(DistributionOrderOverview)}/>
                <Route path="/supply-chain/article/overview" render={this.route(ArticleOverview)}/>
                <Route path="/supply-chain/defect/overview" render={this.route(DefectOverview)}/>

                <Route path="/supplier/overview/:id?" render={(this.route(SupplierDashboard))}/>
                <Route path="/supplier/purchase-order/overview" render={(this.route(PurchaseOrderOverview))}/>
                <Route path="/supplier/transport-order/overview" render={(this.route(TransportOrderOverview))}/>

                <Route path="/jan-krediet/order/overview" render={(this.route(JanKredietOrderOverview))}/>
                <Route path="/jan-krediet/inbound-order/overview" render={(this.route(JanKredietInboundOrderOverview))}/>
                <Route path="/jan-krediet/outbound-order/overview" render={(this.route(JanKredietOutboundOrderOverview))}/>
                <Route path="/jan-krediet/cancelled/overview" render={(this.route(JanKredietCancelledOverview))}/>

                {/*Unauthenticated paths*/}
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>
                <Route path="/transport_order/:id/accept/:token" render={(this.route(AcceptTransportOrder))}/>
                <Route path="/transport_order/:id/decline/:token" render={(this.route(DeclineTransportOrder))}/>

                {moduleRepository.getRoutes().map(moduleRoute => {
                    return <Route path={moduleRoute.path} render={this.route(moduleRoute.screen)}/>;
                })}

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
