import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { ImageStore } from 'store/Image';
import { Article } from 'store/Article';
import { TransportBatch } from 'store/TransportBatch';

export const STATIC_DEFECT_ORIGIN_TRANSPORT = 'transport';
export const STATIC_DEFECT_ORIGIN_DISTRIBUTOR = 'distributor';
export const STATIC_DEFECT_ORIGIN_SUPPLIER = 'supplier';
export const STATIC_DEFECT_ORIGIN_UNKNOWN = 'unknown';
export const STATIC_DEFECT_ORIGIN_OPTIONS = [
    STATIC_DEFECT_ORIGIN_SUPPLIER,
    STATIC_DEFECT_ORIGIN_TRANSPORT,
    STATIC_DEFECT_ORIGIN_DISTRIBUTOR,
    STATIC_DEFECT_ORIGIN_UNKNOWN,
];


export class Defect extends Model {
    static backendResourceName = 'defect';

    @observable id = null;
    @observable origin;
    @observable description;
    @observable quantity = 1;

    relations() {
        return {
            images: ImageStore,
            article: Article,
            transportBatch: TransportBatch,
        };
    }
}

export class DefectStore extends Store {
    Model = Defect;
    static backendResourceName = 'defect';
}